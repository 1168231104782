import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class ColumnChartComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
		let categories 
		if(this.props.categories != undefined ){
			categories = this.props.categories[0]
		}
    	
        // const dStyles={
		// 		width:'100%',
		// 		height:'100%'
		// }
		// console.log("this.props ", this.props)
		// console.log("this.props.series ", this.props.series)
		const highchartoptions = {
	      		 
			chart: {
				type: this.props.chartType,
				width:1250
			},
			title: {
				align: 'left',
				text: this.props.title
			},
			subtitle: {
				align: 'left',
				text: this.props.subtitle
			},
			accessibility: {
				announceNewData: {
					enabled: true
				}
			},
			xAxis: {
				// type: 'category'
				// categories:["2011-2012","2012-2013","2013-2014","2014-2015","2015-2016","2016-2017","2017-2018","2018-2019","2019-2020","2020-2021","2021-2022"]
				categories:categories
			},
			yAxis: {
				title: {
					text: this.props.yaxisTitle
				}
		
			},
			legend: {
				enabled: true
			},
			plotOptions: {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '{point.lbl}'
					}
				},
				column: {
					minPointLength: 3
					}
			},
		
			tooltip: {
				headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.lbl}'
			},
		
			series: this.props.series,
			drilldown: {},
			credits:false
			};

        return ( 
               <HighchartsReact containerProps={{ style: { width: "100%", fontWeight: "bold", fontSize: "25px", color: "black" } }} highcharts={Highcharts} options={highchartoptions} />

			);
		}
}