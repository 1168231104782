import React, { Component } from 'react';
import redirectURL from '../redirectURL';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import MaterialList from './materialList';
import ComparisonCharts from './funcCompMaterialComparisonCharts';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import ConsumptionHistory from './consumptionhistory';
// import YesButton from './yesbutton';
// import UpdateButton from './updatebutton';

// import YesPopup from './YesPopup';

var moment = require("moment");

export default class ForecastResultAnalysis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                // ConsumptionHistory:ConsumptionHistory,
                // YesButton: YesButton,
                // UpdateButton: UpdateButton
            },
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            rowData: [],
            matCodesList: [],
            matCode: { "value": "", label: "Select" },
            loadshow: 'show-n',
            materialPostDate: '',
            lastPredictionDate: '',
            fc_prd: '',
            showCharts: false,
            duration: { "value": 12, label: "12 Months" },
            durationlist: [],
            startDate: new Date("2020-01-01"),
            endDate: new Date("2020-06-30"),
            category: { "value": "", label: "All" },
            categorylist: [],
            showSlideBlockUpload: "sidebarcls",
            overlayBlock: "show-n",
            sliderType: 1,
            sliderTitle: "",
            matGridCode: "",
            matGridDes: '',
            phase: { "value": "2023-Apr-2024-Mar", "label": "2023-Apr-2024-Mar" },
            phaseHalf: {label: "ALL", value: "ALL"},
            chartPhase: { "value": "", "label": "" },
            initialPhase: { "value": "", "label": "" },
            critical_type: { value: "ALL", label: "ALL" },
            phases: [],
            scenario: "",
            scenariosList: [],
            isOpen: false,
            showSlideBlock:""

        }
    }

    async componentDidMount() {

        var categorylist = [
            {
                value: "",
                label: "All"
            },
            {
                value: "Exact",
                label: "Exact"
            }, {
                value: "Excess",
                label: "Excess"
            }, {
                value: "Under",
                label: "Under"
            }
        ]

        this.setState({
            categorylist: categorylist
        })

        // let params = {
        //     start_date : "2020-01-01",
        //     end_date : "2020-06-30"
        // }

        // let params = {
        //     type:this.state.critical_type.value,
        //     phase:this.state.phase.value,
        // }
        // await this.getCompletePredictionData(params)
        // await this.getForecastingPredictionResults()
        //get phases
        redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.reverse(),
                    // phase: recordsarr[0],
                    chartPhase: recordsarr[0],
                    initialPhase: recordsarr[0]
                }, () => { this.getScenariosList(), this.getCompletePredictionData(), this.getForecastingPredictionResults() })
            })

    }

    onClickingSliderCloseIcon = () => {
            this.setState({
                showSlideBlock: "",
                sliderTitle: "",
                loadshow: 'show-n',
                overlayBlock: "show-n",
            })

  }
    getScenariosList = async () => {
        var params = {
            phase: this.state.phase.value
        }
        console.log(params, "uty")
        await redirectURL.post("/dashboard/getForecastResultScenariosList", params)
        .then((response) => {
            var recordsarr = []
            if (response.data.records.length > 0) {
                response.data.records.map((itm) => {
                    recordsarr.push({ value: itm, label: itm });
                })
            }
            this.setState({scenariosList: recordsarr})
        })
    }

    getCompletePredictionData = () => {
        let params = {
            type: this.state.critical_type.value,
            phase: this.state.phase.value,
            scenario: this.state.scenario.value,
            phaseHalf: this.state.phaseHalf.value
        }
        this.setState({ loadshow: 'show-m', overlayBlock: "show-m" })
        redirectURL.post("/forecast/getCompletePredictionData", params)
            .then(async response => {
                console.log(response)
                if (response.status === 200) {
                    let data = JSON.parse(response.data.prediction_data)
                    console.log(data, "show u")
                    var dataarr = []
                    if (data.length > 0) {
                        data.map((itm) => {
                            // console.log("itm.is_anamoly ",itm.is_anamoly)
                            // if(itm.is_anamoly !== undefined && itm.is_anamoly !== "" && itm.is_anamoly !== null && parseInt(itm.is_anamoly) !== 1)
                            if (parseInt(itm.is_anamoly) === 0 || itm.is_anamoly === undefined || parseInt(itm.is_anamoly) === 1) {

                                dataarr.push(itm);
                                // console.log("itm ", itm);
                            }
                            else {
                            }
                        })
                    }
                    await this.setState({
                        rowData: dataarr,
                        loadshow: 'show-n',
                        overlayBlock: "show-n"
                    })
                } else {
                    alert("No Data Found")
                    this.setState({
                        loadshow: 'show-n',
                        overlayBlock: "show-n"
                    })
                }

            })
    }
    getForecastingPredictionResults = (params) => {
        let phase = this.state.phase.value
        // this.setState({loadshow:'show-m'})
        let dictMatCodes = [{ "value": "", label: "All" }];
        let phaseList = [];
        let durationlist = [];
        redirectURL.post("/forecast/getForecastingPredictionResults", params)
            .then(async response => {
                if (response.data.status == 'success') {
                    let records = response.data.predictionResults
                    let matCodes = response.data.matCode
                    let distinctPhases = response.data.distinctPhases
                    let distinctFcs = response.data.distinctFcs
                    await matCodes.map(item => { dictMatCodes.push({ label: item, value: item }) })
                    await distinctPhases.map(item => { phaseList.push({ label: item, value: item }) })
                    await distinctFcs.map(item => { durationlist.push({ label: `${item} Months`, value: item }) })

                    // await redirectURL.post('/forecast/getDataFromMaterialsMaster')
                    //     .then(async response =>{
                    //         let rec = response.data;
                    //         await records.map(item =>{
                    //             rec.map(recItem =>{
                    //                 if(recItem.item_code == item.item_code){
                    //                     item.item_description = recItem.item_description;
                    //                     item.cost_in_USD_per_item = recItem.cost_in_USD_per_item;
                    //                 }
                    //             })
                    //         })
                    //     })

                    // await redirectURL.post("/forecast/getActualConsumedDataFromFutureMaterils",params)
                    // .then(async response =>{
                    //     if(response.data.length>0){
                    //         let actualRecords = response.data
                    //         await records.map(item =>{
                    //             actualRecords.map(actualItem =>{
                    //                 if(item.item_code == actualItem.item_code){

                    //                     item.actual_consumed_qty = actualItem.actual_consumed_qty;
                    //                     item.actual_cost = (item.cost_in_USD_per_item * actualItem.actual_consumed_qty).toFixed(2);
                    //                     item.total_predicted_cost = (item.item_quantity * item.cost_in_USD_per_item).toFixed(2);
                    //                     item.cost_difference = (item.total_predicted_cost - item.actual_cost).toFixed(2);

                    //                 }
                    //             })

                    //         })
                    //     }
                    // })
                    // await redirectURL.post("/forecast/getActualConsumedData")
                    // .then( async response =>{
                    //     // console.log(response.data,response.data.length)
                    //     if(response.data.length>0){
                    //         let actualRecords = response.data
                    //         await records.map(item =>{
                    //             actualRecords.map(actualItem =>{
                    //                 if(item.item_code == actualItem.item_code){
                    //                     item.cost_in_USD_per_item = actualItem.cost_in_USD_per_item;
                    //                     item.actual_consumed_qty = actualItem.actual_consumed_qty;
                    //                     item.actual_cost = (actualItem.cost_in_USD_per_item * actualItem.actual_consumed_qty).toFixed(2);
                    //                     item.total_predicted_cost = (item.item_quantity * actualItem.cost_in_USD_per_item).toFixed(2);
                    //                     item.cost_difference = (item.total_predicted_cost - item.actual_cost).toFixed(2);


                    //                 }
                    //             })

                    //         })

                    //     }

                    // })

                    // console.log(records,'re')
                    await this.setState({
                        // rowData:records,
                        matCodesList: dictMatCodes,
                        phaseList: phaseList,
                        durationlist: durationlist
                        // matCode:{"value":"",label:"All"},
                        // showCharts:false,
                        // loadshow:'show-n'
                    })
                }
            })

    }


    updateMaterialCode = (newValue) => {
        let lastPredictionDate = this.state.lastPredictionDate;
        let materialPostDate = this.state.materialPostDate;
        let fc_prd = this.state.fc_prd;
        let phase = this.state.phase

        this.setState({ matCode: newValue })
        if (newValue.value === '') {
            this.setState({ showCharts: false })
        }

        //     if(newValue.value != ''){
        //     let params={
        //         item_code:newValue.value
        //     }
        //     redirectURL.post("/forecast/getMaterialDatesData",params)
        //     .then(response =>{
        //         let predDate = response.data.predRecord;
        //         let matDate = response.data.matRecord;

        //         if (predDate.length>0){
        //             let year = predDate[0].year;
        //             let month = predDate[0].month;
        //             fc_prd = predDate[0].fc_period

        //             lastPredictionDate = getFirstDateOfMonth(month-1, year);


        //         }
        //         if(matDate.length>0){
        //              let matyear = new Date(matDate[0].posting_date).getFullYear()

        //              //code needs to be changed
        //              if(matyear != 2020){
        //                 materialPostDate = getLastDateOfMonth(6, 2020);   
        //              }else{
        //              materialPostDate = getLastDateOfMonth(6, matyear);
        //              }

        //         }

        //         this.setState({
        //             lastPredictionDate:lastPredictionDate,
        //             materialPostDate:materialPostDate,
        //             fc_prd:fc_prd
        //         })
        //     })
        // }

    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onBtnExportCSV = () => {
        console.log("hey export csv")
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Result Analysis_${timestamp}`;
        this.gridApi.exportDataAsCsv(
            {
                fileName: filename
            }
        );
    };
    onBtnExportExcel = () => {
        const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");
        const filename = `Forecast_Result Analysis_${timestamp}`;
        this.gridApi.exportDataAsExcel(
            {
                fileName: filename
            }
        );
    };

    showCharts_ = () => {
        let matCode = this.state.matCode;

        if (matCode.value != "") {
            this.setState((prevState) => ({
                showCharts: !prevState.showCharts
            }));
        } else {
            alert("Please select Material")
            this.setState({
                showCharts: false
            })
        }
    }


    showCharts = () => {
        let matCode = this.state.matGridCode;

        if (matCode != "") {
            this.setState((prevState) => ({
                showCharts: !prevState.showCharts
            }));
        } else {
            alert("Please select Material")
            this.setState({
                showCharts: false,
            })
        }
        this.setState({ chartPhase: this.state.initialPhase })
    }

    onChangePredictionPhase = (phase) => {

        this.setState({ chartPhase: phase })
    }

    getPhaseWiseData = () => {
        // let start_date = moment.parseZone(new Date((this.state.startDate))).format("YYYY-MM-DD")
        // let end_date = moment.parseZone(new Date((this.state.endDate))).format("YYYY-MM-DD")
        // let duration =this.state.duration.value

        // let params = {
        //     start_date : start_date,
        //     end_date : end_date
        // }
        let params = {
            type: this.state.critical_type.value,
            phase: this.state.phase.value,
        }
        this.getCompletePredictionData(params)

    }


    onChangeDuration(duration) {
        this.setState({ duration })
    }

    setStartDate = (date) => {
        this.setState({ startDate: date })
    }
    setEndDate = (date) => {
        this.setState({ endDate: date })
    }

    onChangeCategory(category) {
        this.setState({ category })
    }


    onClickExport = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            sliderType: 1,
            sliderTitle: "Export Table Data Using Below Options",
            overlayBlock: "show-m",
        })
    }


    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
            showSlideBlock:""
        })
    }
    onClickShowClusterRouteOnMap(rownode) {
        // console.log("row475 ", rownode)
        if (rownode.colDef.field === "item_code") {
            console.log(this.state.phase)
            this.setState({
                chartPhase: this.state.phase,
                matGridCode: rownode.data.item_code,
                matGridDes: rownode.data.item_description,
                showSlideBlock: "slide90",
                sliderTitle: "Forecast Result Analysis",
                loadshow: 'show-n',
                overlayBlock: "show-m",
            })
        }
    }

    onChangePhase(phase) {
        this.setState({ phase, scenario: "" }, () => {this.getScenariosList()})
    }
    onChangePhaseHalf(phaseHalf) {
        this.setState({ phaseHalf })
    }
    onChangeChartPhase(chartPhase) {
        this.setState({ chartPhase })
    }
    onChangeCriticalType(critical_type) {
        this.setState({ critical_type })
    }
    onChangeScenario(scenario){
        this.setState({scenario})
    }

    // renderYesButton = params => {
    //     return (
    //         <div>
    //             <button
    //                 type="button"
    //                 onClick={() => this.handleYesClick(params.data)}
    //                 className="btn btn-danger grid-btn"
    //                 style={{ border: "0px" }}
    //             >
    //                  Yes
    //             </button>
    //         </div>
    //     )
    // }

    handleYesClick = rowData => {
        this.setState({
            isOpen: true,
            // selectedRowData: rowData,
        });
    };
    handleClosePopup = () => {
        this.setState({
            isOpen: false,
            selectedRowData: null,
        });
    };

    render() {


        // var newcolumnwithDefs = [
        //     {
        //         headerName: "Phase",
        //         field: "phase",
        //         width: 130,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "Material Code",
        //         field: "item_code",
        //         width: 150,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "Consumption History",
        //         field: "consumptionhistory",
        //         width: 250,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //         cellRendererSelector: function (params) {

        //             var rendComponent = {
        //                 component: "ConsumptionHistory"
        //             };
        //             return rendComponent

        //         },
        //     },
        //     {
        //         headerName: "AI/ML Predicted Quantity",
        //         field: "ai_ml_model_prediction",
        //         width: 210,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"]
        //     },
        //     {
        //         headerName: "Reject Prediction",
        //         field: "yesbutton",
        //         width: 157,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //         cellRendererFramework: this.renderYesButton,
        //     },
        //     {
        //         headerName: "Updated Prediction Quantity",
        //         field: "quantity",
        //         width: 240,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //     },
        //     {
        //         headerName: "Current Inventory Quantity",
        //         field: "current_quantity",
        //         width: 250,
        //         filter: true,
        //         resizable: true,
        //         editable: false,
        //         headerClass: ["column-header-style"],
        //     }
            
        // ]

        var columnwithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                tooltipField: "popupText",
                headerClass: ["column-header-style"],
                cellClass: function (params) {
                    try {
                        if (params.data.category != undefined || params.data.category != "") {
                            if (params.data.category == "Excess") {
                                return ["redbg", "fbold"]
                            } else if (params.data.category == "Exact") {
                                return ["greenbg", "fbold"]
                            } else if (params.data.category == "Under") {
                                return ["orangebg", "fbold"]
                            }
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 260,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Material Type",
                field: "material_type",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
              headerName: "Material Part Type",
              field: "material_part_type",
              width: 198,
              filter: true,
              resizable: true,
              editable: false,
              headerClass: ["column-header-style"],
              // cellClass:function(params){
              //     try {
              //         if(params.data.category != undefined || params.data.category != "")
              //         {
              //             if(params.data.category == "Excess"){
              //                 return "orangebg"
              //             }else if(params.data.category == "Exact"){
              //                 return "greenbg"
              //             }else{
              //                 return "redbg"
              //             }
              //         }
              //     } catch (error) {

              //     }
              // }
          },
          {
            headerName: "Material Department",
            field: "material_department",
            width: 198,
            filter: true,
            resizable: true,
            editable: false,
            headerClass: ["column-header-style"],
            // cellClass:function(params){
            //     try {
            //         if(params.data.category != undefined || params.data.category != "")
            //         {
            //             if(params.data.category == "Excess"){
            //                 return "orangebg"
            //             }else if(params.data.category == "Exact"){
            //                 return "greenbg"
            //             }else{
            //                 return "redbg"
            //             }
            //         }
            //     } catch (error) {

            //     }
            // }
        },
            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
            },
            {
                headerName: "Prediction Category",
                field: "category",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"]
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Unit Cost ( $ )",
                field: "unit_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Forecast Horizon(months)",
                field: "fc_period",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Predicted Quantity",
                field: "predicted_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                // valueGetter:function(params){
                //     try {
                //         if(params.data.predicted_quantity !== "" && params.data.predicted_quantity !== undefined)
                //         {
                //             return params.data.predicted_quantity +" ( "+params.data.predicted_cost+" )"
                //         }
                //     }
                //     catch(e){

                //     }
                // },
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: "predicted_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
            },
            {
                headerName: "Actual Quantity",
                field: "actual_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
                // valueGetter:function(params){
                //     try {
                //         if(params.data.actual_quantity !== "" && params.data.actual_quantity !== undefined)
                //         {
                //             if(params.data.actual_quantity == null){
                //                 return ''
                //             }else{
                //                 return params.data.actual_quantity +" ( "+params.data.actual_cost+" )"
                //             }

                //         }
                //     }
                //     catch(e){

                //     }
                // },
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Actual Cost ( $ )",
                field: "actual_cost",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
            },
            {
                headerName: "Cost Difference (Predicted - Actual)",
                field: "cost_difference",
                width: 200,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
                // cellClass:function(params){
                //     try {
                //         if(params.data.category != undefined || params.data.category != "")
                //         {
                //             if(params.data.category == "Excess"){
                //                 return "orangebg"
                //             }else if(params.data.category == "Exact"){
                //                 return "greenbg"
                //             }else{
                //                 return "redbg"
                //             }
                //         }
                //     } catch (error) {

                //     }
                // }
            },
            {
                headerName: "Inventory Quantity",
                field: "existing_inventory_quantity",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
                valueGetter:function(params){
                    try {
                        if(params.data.existing_inventory_quantity != null)
                        {
                            return params.data.existing_inventory_quantity
                        }
                        else{
                            return 0
                        }
                    }
                    catch(e){

                    }
                },
            },
            {
                headerName: "Predicted Quantity After Inventory Check",
                field: "predicted_quantity_after_inventory",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
                valueGetter:function(params){
                    try {
                        if(params.data.predicted_quantity_after_inventory != null)
                        {
                            return params.data.predicted_quantity_after_inventory
                        }
                        else{
                            return 0
                        }
                    }
                    catch(e){

                    }
                },
            },
            {
                headerName: "Predicted Cost After Inventory Check",
                field: "predicted_cost_after_inventory",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                hide: false,
                headerClass: ["column-header-style"],
                valueGetter:function(params){
                    try {
                        if(params.data.predicted_cost_after_inventory != null)
                        {
                            return params.data.predicted_cost_after_inventory
                        }
                        else{
                            return 0
                        }
                    }
                    catch(e){

                    }
                },
            },
            // {
            //     headerName: "Insufficient Data for Prediction",
            //     field: "less_consumption",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     hide: true
            // },
            // {
            //     headerName: "Possible Outlier ( No Consumption for Last Three Years )",
            //     field: "outlier",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            // }
            // {
            //     headerName: "Category",
            //     field: "error_type",
            //     width:160,
            //     filter: true,
            //     resizable: true,
            //     editable:false,
            //     valueGetter:function(params){
            //         try {
            //             if(params.data.cost_difference !== "" && params.data.cost_difference !== undefined)
            //             {
            //                 if(params.data.cost_difference > 0){
            //                     return "Excess"
            //                 }else if(params.data.cost_difference == 0){
            //                     return "Exact"
            //                 }else{
            //                     return "Under"
            //                 }

            //             }
            //         } catch (error) {

            //         }
            //     }
            // },
        ]

        //to filter data in table
        let data = this.state.rowData
        data = data.map((each) => ({
            ...each, popupText: "Double click to see"
        }))
        let forecastitemVal = this.state.matCode.value
        let category = this.state.category.value

        let filteredData = data.filter(item => {

            if (category != "") {
                if (item.item_code == forecastitemVal && item.category == category) {
                    return true
                } else {
                    return false
                }
            } else {
                if (item.item_code == forecastitemVal) {
                    return true
                } else {
                    return false
                }
            }


        })
        if (filteredData.length > 0) {

        } else if (forecastitemVal == '') {

            if (category != "") {
                filteredData = data.filter(item => {

                    if (item.category == category) {
                        return true
                    } else {
                        return false
                    }
                })
            } else {
                filteredData = data
            }

        } else {
            filteredData = []
        }
        const checkData = filteredData.some(row => ((row.actual_quantity === null || row.actual_quantity === undefined || row.actual_quantity === "") && (row.actual_cost === null || row.actual_cost === undefined || row.actual_cost === "") && (row.cost_diiference === null || row.cost_diiference === undefined || row.cost_diiference === "") && (row.category === null || row.category === undefined || row.category === "")));
        console.log(checkData, "checkData")
        // Conditionally show/hide the 'Less Consumption' and 'outlier' columns
        if (checkData) {
            // columnwithDefs[10].hide = false; // Show 'Less Consumption'
            columnwithDefs[4].hide = true;
            columnwithDefs[9].hide = true; // Show 'outlier'
            columnwithDefs[10].hide = true;
            columnwithDefs[11].hide = true;
        }
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),
        };
        const new_data = [
            {"item_code": "100057087", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 105, "current_quantity": 10},
            {"item_code": "100059737", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 105, "current_quantity": 20},
            {"item_code": "100056236", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 113, "current_quantity": 9},
            {"item_code": "100074956", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 128, "current_quantity": 13},
            {"item_code": "100089852", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 1283, "current_quantity": 40},
            {"item_code": "200001661", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 1304, "current_quantity": 53},
            {"item_code": "100050017", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 1354, "current_quantity": 57},
            {"item_code": "200001450", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 1479, "current_quantity": 63},
            {"item_code": "100110505", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 1546, "current_quantity": 39},
            {"item_code": "200003715", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 21432, "current_quantity": 44},
            {"item_code": "200001669", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 2708, "current_quantity": 29},
            {"item_code": "200003689", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 45120, "current_quantity": 90},
            {"item_code": "100071319", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 761, "current_quantity": 78},
            {"item_code": "100109860", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 89, "current_quantity": 13},
            {"item_code": "100058677", "phase": "2023-Jul-Oct", "ai_ml_model_prediction": 93, "current_quantity": 30}
        ]

        return (
            <div className="container-fluid">
              <div className="row mb-5p">
                <div className="col-sm-4">
                  <h5 className="fbold f20" style={{ color: "black" }}>
                    Forecast Result Analysis
                  </h5>
                </div>
                {/* <div className="col-md-8">
                  <div className="form-group col-md-3 float-right">
                    <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Category</label>
                    <Select
                      placeholder={"Select"}
                      onChange={this.onChangeCategory.bind(this)}
                      name="category"
                      value={this.state.category}
                      options={this.state.categorylist}
                      styles={customStyles}
                    />
                  </div>
                </div> */}
              </div>
              <div className={"row mb-20p"}>
                <div className="col-md-12">
                  <div className="form-group float-right">
                    {this.state.showCharts && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.showCharts}
                      >
                        {this.state.showCharts ? "Go Back" : "Chart View"}
                      </button>
                    )}
                  </div>
                </div>
                <div className={"col-sm-12"}>
                  {this.state.showCharts !== true && (
                    <div className="row">
                        <div className="col-11" style={{paddingLeft: "0px"}}>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Material</label>
                          <MaterialList options={this.state.matCodesList} updateMaterialCode={this.updateMaterialCode} />
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Phase</label>
                        <Select
                          onChange={this.onChangePhase.bind(this)}
                          options={this.state.phases}
                          value={this.state.phase}
                          styles={customStyles}
                        />
                      </div>
                      <div className="col-sm-2">
                        <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Phase Half</label>
                        <Select
                          onChange={this.onChangePhaseHalf.bind(this)}
                          options={[
                            { value: "first", label: "First Half" },
                            { value: "second", label: "Second Half" },
                            { value: "ALL", label: "ALL" },
                          ]}
                          value={this.state.phaseHalf}
                          styles={customStyles}
                        />
                      </div>
                      <div className="col-sm-2">
                        <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Material Type</label>
                        <Select
                          onChange={this.onChangeCriticalType.bind(this)}
                          options={[
                            { value: "HIGH CRITICAL", label: "HIGH CRITICAL" },
                            { value: "LOW CRITICAL", label: "LOW CRITICAL" },
                            { value: "ALL", label: "All" },
                          ]}
                          value={this.state.critical_type}
                          styles={customStyles}
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="col-form-label fw-600 f15" style={{ color: "black" }}>Forecast Horizon</label>
                          <Select
                            placeholder={"Select"}
                            onChange={this.onChangeDuration.bind(this)}
                            name="duration"
                            value={this.state.duration}
                            options={this.state.durationlist}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="col-form-label fw-600 f15" style={{ color: "black" }}>Scenario</label>
                          <Select
                            placeholder={"Select"}
                            onChange={this.onChangeScenario.bind(this)}
                            name="duration"
                            value={this.state.scenario}
                            options={this.state.scenariosList}
                            styles={customStyles}
                          />
                          {/* <input type='text' className="form-inputbox forminp" value={"180"} /> */}
                        </div>
                      </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-danger mt-40p"
                            onClick={this.getPhaseWiseData}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
          
                  {/* {this.state.showCharts && (
                    <>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Prediction Horizon</label>
                          <Select
                            placeholder={"Select"}
                            onChange={this.onChangePredictionPhase}
                            name="phase"
                            value={this.state.chartPhase}
                            options={this.state.phases}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Forecast Horizon</label>
                          <Select
                            placeholder={"Select"}
                            onChange={this.onChangeDuration.bind(this)}
                            name="duration"
                            value={this.state.duration}
                            options={this.state.durationlist}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </>
                  )} */}
          
                  {this.state.showCharts === false ? (
                    <div className="row">
                      <div className="col-sm-12 legend-container">
                        <div className="d-flex">
                          <div className="color-indicator-box">
                            <div className="color-box greenbg"></div>
                            <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Exact</label>
                          </div>
                          <div className="color-indicator-box">
                            <div className="color-box orangebg"></div>
                            <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Under</label>
                          </div>
                          <div className="color-indicator-box">
                            <div className="color-box redbg"></div>
                            <label className="col-form-label f15 fw-600" style={{ color: "black" }}>Excess</label>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-info float-right"
                          onClick={this.onClickExport}
                        >
                          Download Data
                        </button>
                        {/* <button type="button" className="btn btn-info float-right" onClick={this.onBtnExportCSV}>Export to CSV</button>
                          <button type="button" className="btn btn-secondary float-right" onClick={this.onBtnExportExcel}>Export to Excel</button> */}
                      </div>
                      <div className={"col-sm-12 "} >
                      <div
                        id="myGrid1"
                        style={{
                          width: "100%",
                          height: "70vh",
                          fontSize: "13px",
                          fontWeight: "500"
                        }}
                        className={"ag-theme-balham"}
                      >
                        <AgGridReact
                          columnDefs={columnwithDefs}
                          defaultColDef={this.state.defaultColDef}
                          rowData={filteredData}
                          enableCharts={true}
                          onGridReady={this.onGridReady}
                          onGridState={this.onGridState}
                          frameworkComponents={this.state.frameworkComponents}
                          statusBar={this.state.statusBar}
                          sideBar={this.state.sideBar}
                          stopEditingWhenGridLosesFocus={true}
                          paginationPageSize={this.state.paginationPageSize}
                          pagination={true}
                          gridOptions={{
                            context: { componentParent: this },
                            rowClass: 'your-row-class', // Set the row class for styling
                          }}
                          onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                          enableRangeSelection={true}
                          masterDetail={true}
                          detailCellRendererParams={this.state.detailCellRendererParams}
                          suppressRowHoverHighlight={true}
                        />
                      </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-12 mt-30p f20 fw-600" style={{ color: "black" }}>
                      <b>Material Code: {this.state.matGridCode} ({this.state.matGridDes})</b>
                    </div>
                  )}
          
                  {this.state.showCharts && (
                    <ComparisonCharts
                      phase={this.state.chartPhase.value}
                      materialCode={this.state.matGridCode}
                      period={this.state.duration.value !== '' ? this.state.duration.value : 6}
                    />
                  )}
                </div>
              </div>
              <div className={"overlay-block " + this.state.overlayBlock} onClick={this.hideSlideBlock}></div>
              <div className={"sliderBlock2 " + this.state.showSlideBlockUpload}>
                <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                <div className="row">
                  {this.state.sliderType === 1 && (
                    <div className="col-md-12 pad-left-5per">
                      <button type="button" className="btn btn-info" onClick={this.onBtnExportCSV}>Export to CSV</button>
                      <button type="button" className="btn btn-primary" onClick={this.onBtnExportExcel}>Export to Excel</button>
                    </div>
                  )}
                </div>
              </div>
              {this.state.loadshow === "show-m" && (
                <>
                  <div className="new-loader" style={{ zIndex: "9999" }}>
                    <div className="bar bar1"></div>
                    <div className="bar bar2"></div>
                    <div className="bar bar3"></div>
                    <div className="bar bar4"></div>
                    <div className="bar bar5"></div>
                    <div className="bar bar6"></div>
                    <div className="bar bar7"></div>
                    <div className="bar bar8"></div>
                  </div>
                  <div
                    className="col-12 fw-600 f20"
                    style={{
                      color: "black",
                      position: "absolute",
                      top: "54%",
                      left: "47%",
                      zIndex: "9999",
                    }}
                  >
                    <p>Loading...</p>
                  </div>
                </>
              )}
 
              <div className={"sliderBlock2 " + (this.state.showSlideBlock)} style={{ zIndex: "100000000" }}>
                    <div className="slider-header-container d-flex justify-content-between"  style={{backgroundColor:"#0000001f"}}>
                        {/* <button onClick={() => setSliderOpen(false)}>Close Slider</button> */}
                        <p className="m-0p" style={{fontSize: "1.3vw"}}>Material Code: {this.state.matGridCode}</p>
                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "#0000001f", borderRadius: "50%", width: "30px", height: "30px", padding: "5px" }}>
                            <p className="" style={{ cursor: "pointer", margin: "0px" }} onClick={this.onClickingSliderCloseIcon}>X</p>
                        </div>
                    </div>
                    <div className='mt-1 mb-1 p-1'>
                    <p className="pl-2" style={{fontSize: "1.3vw",color:"#000",fontSize:"18px",fontWeight:"bold"}}>Material Description: {this.state.matGridDes}</p>

                    </div>
                      <div>
                      <>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Prediction Horizon</label>
                              <Select
                                placeholder={"Select"}
                                onChange={this.onChangePredictionPhase}
                                name="phase"
                                value={this.state.chartPhase}
                                options={this.state.phases}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="col-form-label fw-600 f17" style={{ color: "black" }}>Forecast Horizon</label>
                              <Select
                                placeholder={"Select"}
                                onChange={this.onChangeDuration.bind(this)}
                                name="duration"
                                value={this.state.duration}
                                options={this.state.durationlist}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </>
                      </div>

                      <div >
                                          
                      <ComparisonCharts
                          phase={this.state.chartPhase.value}
                          materialCode={this.state.matGridCode}
                          period={this.state.duration.value !== '' ? this.state.duration.value : 6}
                        /> 

                      </div>
                      <div>
                  
                  </div>
              </div>

            </div>
          );
           }
}

function getLastDateOfMonth(monthNumber, year) {

    var lastDate = moment.parseZone(new Date(year, monthNumber, 0)).format("YYYY-MM-DD");
    return lastDate;
}

function getFirstDateOfMonth(monthNumber, year) {

    var firstDate = moment.parseZone(new Date(year, monthNumber, 1)).format("YYYY-MM-DD");
    return firstDate;
}